import * as React from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import DateFilterBase from './DateFilterBase';
import { selectFilter, setFromDate } from './filterSlice';

interface Props {
  placeholder: string | null;
  showFormat: boolean;
}

export default function DateFilterFrom(props: Props) {
  const { placeholder, showFormat } = props;
  const filter = useAppSelector(selectFilter);
  const dispatch = useAppDispatch();
  const handleChange = React.useCallback(
    (date: unknown) => {
      if ((date as any)?.toString() === 'Invalid Date') {
        return;
      }
      dispatch(setFromDate((date as Date)?.getTime() ?? -1));
    },
    [dispatch],
  );
  return (
    <DateFilterBase
      showFormat={!showFormat}
      label={placeholder ?? 'Any Date'}
      value={[-1, -2].includes(filter.dateRange.from) ? null : new Date(filter.dateRange.from)}
      handleChange={handleChange}
    />
  );
}
