import * as React from 'react';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  InputBase, Theme, Button, Paper, Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../hooks';
import { selectSearch, setGeneralSearchText, setProducts } from '../../features/search/searchSlice';
import ProjectsFilterForm from '../../features/filter/ProjectsFilterForm';
import DateFilterFrom from '../../features/filter/DateFilterFrom';
import DateFilterTo from '../../features/filter/DateFilterTo';
import SolutionFilterForm from '../../features/filter/SolutionFilterForm';
import {
  selectAvailableSolutions,
  selectAvailableProjects,
  applyFilter,
  selectFilter,
  setProjects,
  setWorkItemTypeBug,
  setWorkItemTypeUserStory,
  setFromDate,
  setToDate,
  validate,
} from '../../features/filter/filterSlice';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    margin: 'auto',
    minHeight: 'calc(100vh - 160px)',
    // marginTop: 96,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  filterForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 25,
    minWidth: '35%',
    maxWidth: 600,
    borderColor: 'white',
  },
  formRoot: {
    textAlign: 'left',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: 20,
    marginBottom: 20,
  },
  searchBar: {
    display: 'flex',
    flexDirection: 'row',
    minWidth: '40%',
  },
  search: {
    border: 'lightgrey 1px solid',
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    display: 'flex',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  searchIcon: {
    padding: theme.spacing(2),
    height: '100%',
    pointerEvents: 'none',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    alignSelf: 'center',
  },
  searchAnimation: {
    transition: theme.transitions.create('width'),
  },
  searchAnimationOpen: {
    width: 0,
  },
  searchAnimationClosed: {
    width: 40,
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    width: 'inherit',
  },
  removeInputButtonCollapsed: {
    visibility: 'collapse',
    padding: 18,
  },
  removeInputButtonVisible: {
    visibility: 'visible',
    padding: 18,
  },
  productPlacer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px',
  },
  list: {
    width: '100%',
  },
  productList: {
    paddingBottom: 5,
  },
  showLabelList: {
    transform: 'translateY(7px)',
    paddingBottom: 5,
    paddingTop: 5,
  },
  hideSolutionScrollbar: {
    visibility: 'hidden',
    display: 'none',
    overflow: 'hidden',
  },
  dateFilter: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    minWidth: '40%',
  },
  dateForm: {
    width: '50%',
  },
  dateFromPadding: {
    paddingTop: 20,
    paddingRight: 20,
  },
  dateToPadding: {
    paddingTop: 20,
    paddingLeft: 20,
  },
  submitButton: {
    marginTop: 20,
    padding: '5px 40px',
  },
}));

interface Props {
  // eslint-disable-next-line react/require-default-props
  executeOnOpen?: () => void;
  // eslint-disable-next-line react/require-default-props
  executeOnClose?: () => void;
}

export default function SearchForm(props: Props) {
  const classes = useStyles();
  const searchBarRef = React.useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const search = useAppSelector(selectSearch);
  const availableSolutions = useAppSelector(selectAvailableSolutions);
  const availableProjects = useAppSelector(selectAvailableProjects);
  const filter = useAppSelector(selectFilter);
  const { executeOnOpen, executeOnClose } = props;

  const [focused, setFocused] = React.useState(false);
  const searchAnimationClass = focused
    ? classes.searchAnimationOpen
    : classes.searchAnimationClosed;

  const handleSearch = () => {
    dispatch(setProjects(availableProjects.map((x) => x.name)));
    dispatch(setWorkItemTypeBug(true));
    dispatch(setWorkItemTypeUserStory(true));
    dispatch(setFromDate(-1));
    dispatch(setToDate(-1));
    dispatch(applyFilter());
    history.push('/workitems');
    if (executeOnClose !== undefined) {
      executeOnClose();
    }
  };

  const goButtonClick = () => {
    const currentSearchParams = new URLSearchParams(window.location.search);

    dispatch(applyFilter());
    const DisplaynamesOfSelectedProjects: string[] = [];
    filter.projects.forEach((project) => {
      const selectedProject = availableProjects.find((x) => x.name === project);
      if (selectedProject !== undefined) {
        DisplaynamesOfSelectedProjects.push(selectedProject.displayName);
      }
    });
    dispatch(setProducts([...DisplaynamesOfSelectedProjects]));
    history.push({
      pathname: '/workitems',
      search: currentSearchParams.toString(),
    });
    if (executeOnClose !== undefined) {
      executeOnClose();
    }
  };

  React.useEffect(() => {
    if (executeOnOpen !== undefined) {
      executeOnOpen();
    }
  }, []);

  React.useEffect(() => {
    dispatch(validate());
  }, [filter]);

  return (
    <Paper variant="outlined" className={classes.filterForm}>
      <div className={classes.formRoot}>
        <div className={classes.searchBar}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <div className={clsx(classes.searchAnimation, searchAnimationClass)} />
            <InputBase
              inputRef={searchBarRef}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              placeholder="search for keyword, product, ticket number, ..."
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              onChange={(e) => {
                dispatch(setGeneralSearchText(e.target.value));
              }}
              onKeyUp={(e) => {
                if (e.keyCode === 13) {
                  handleSearch();
                }
              }}
              value={search.generalSearchText}
              inputProps={{ 'aria-label': 'search' }}
            />
            <Button
              className={
                search.generalSearchText === ''
                  ? classes.removeInputButtonCollapsed
                  : classes.removeInputButtonVisible
              }
              onClick={() => {
                dispatch(setGeneralSearchText(''));
                searchBarRef.current?.focus();
              }}
            >
              <svg viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
                />
              </svg>
            </Button>
          </div>
        </div>
        <div className={classes.productPlacer}>
          <div className={clsx(classes.list, classes.productList)}>
            <Typography variant="body2" className={classes.showLabelList}>
              Products
            </Typography>
            <ProjectsFilterForm />
          </div>
          <div
            className={
              availableSolutions.length >= 1
                ? classes.list
                : clsx(classes.list, classes.hideSolutionScrollbar)
            }
          >
            <Typography variant="body2" className={classes.showLabelList}>
              Solutions
            </Typography>
            <SolutionFilterForm />
          </div>
        </div>
        <div className={classes.dateFilter}>
          <div className={classes.dateForm}>
            <div className={classes.dateFromPadding}>
              <DateFilterFrom placeholder="Date from" showFormat />
            </div>
          </div>
          <div className={classes.dateForm}>
            <div className={classes.dateToPadding}>
              <DateFilterTo placeholder="Date to" showFormat />
            </div>
          </div>
        </div>
      </div>
      <Button
        color="primary"
        disableElevation
        variant="contained"
        className={classes.submitButton}
        onClick={goButtonClick}
        disabled={!filter.valid}
      >
        GO
      </Button>
    </Paper>
  );
}
