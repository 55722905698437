// This class changes the terms "bug" and "feature", which are rather unknown to users, to terms that are easier to understand.
export default class TypeParser {
  // In the case that we want to adjust the type of a work item, the switch is made so that we get the conventional value from the user-specified terms "Fixed" and "Added".
  static parseType(value: string): string {
    switch (value) {
      case 'Added': {
        return 'User Story';
      }
      case 'Fixed': {
        return 'Bug';
      }
      case 'Bug': {
        return 'Fixed';
      }
      case 'User Story': {
        return 'Added';
      }
      default: {
        return '';
      }
    }
  }
}
