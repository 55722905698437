// eslint-disable-next-line no-unused-vars
import * as React from 'react';
import { makeStyles } from '@mui/styles';
import {
  Card, CardContent, Chip, Divider, Typography,
} from '@mui/material';
import parse from 'html-react-parser';
import clsx from 'clsx';
import { AutoStories, BugReport } from '@mui/icons-material';
import { WorkItem } from './Types';

interface Props {
  wi: WorkItem;
}

const useStyles = makeStyles({
  divider: {
    marginBottom: 10,
    marginTop: 5,
  },
  paddingTop: {
    paddingTop: 10,
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  flexBoxTitle: {
    display: 'flex',
    flexDirection: 'row',
  },
  icon: {
    marginTop: 5,
    height: 20,
    marginRight: 5,
  },
  bugIcon: {
    color: '#7d232a',
  },
  storyIcon: {
    color: '#234f91',
  },
});

export default function WorkItemDetail(props: Props) {
  const { wi } = props;
  const classes = useStyles();
  return (
    <Card>
      <CardContent>
        <div className={classes.flexBoxTitle}>
          <div>
            {wi.type === 'Bug' ? (
              <BugReport className={clsx(classes.icon, classes.bugIcon)} />
            ) : (
              <AutoStories className={clsx(classes.icon, classes.storyIcon)} />
            )}
          </div>
          <div>
            <Typography sx={{ fontSize: 20 }}>{`${wi.id}`}</Typography>
          </div>
        </div>
        <Typography sx={{ fontSize: 14 }} color="text.secondary">
          {`${wi.type} - ${wi.productDisplayName} ${
            wi.version.length > 0 && wi.version !== '-' ? `, integrated in ${wi.version}` : ''
          }`}
        </Typography>

        <Divider className={classes.divider} />
        <div>{parse(wi.title)}</div>
        {wi.tags.length > 1 && wi.useTags === true && (
          <Typography className={clsx(classes.paddingTop, classes.subtitle)}>Tags</Typography>
        )}
        <div className={classes.paddingTop}>
          {wi.tags.length >= 2 && wi.useTags === true
            ? wi.tags
              .split('; ')
              .map((x) => (
                <Chip
                  style={{ marginRight: 5 }}
                  key={wi.id}
                  label={x.trim()}
                  variant="outlined"
                />
              ))
            : ''}
        </div>
      </CardContent>
    </Card>
  );
}
