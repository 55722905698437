/* eslint-disable no-unused-vars */
export type Order = 'asc' | 'desc';

/**
 * compares two properties of objects a and b
 * @param a first object
 * @param b second object
 * @param orderBy property of object that will be compared
 * @returns -1 if b.orderBy < a.orderBy, 1 if a.orderBy > b.orderBy, otherwise 0
 */
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
  // eslint-disable-next-line no-unused-vars
): (
  a: { [key in Key]: number | string | Date | boolean },
  b: { [key in Key]: number | string | Date | boolean },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

/**
 * sorts an array using 'stable - sort'
 * @param array array to sort
 * @param comparator comparator to use for sorting
 */
// eslint-disable-next-line no-unused-vars
export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
