import React from 'react';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => createStyles({
  root: {
    width: '100%',
    height: '64px',
    backgroundImage: `url(${process.env.PUBLIC_URL}/footer-background.png)`,
    backgroundPosition: 'bottom',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  footerText: {
    color: 'white',
    margin: '0px 7px',
  },
  footerLink: {
    color: 'white',
    textDecoration: 'none',
    '&:hover': {
      textShadow: '0 0 5px #ffffff',
      color: 'white',
      textDecoration: 'none',
    },
  },
}));

function CustomFooter() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <p className={classes.footerText}>
        ©
        {' '}
        {new Date().getFullYear()}
        {' '}
        VertiGIS. All rights reserved.
      </p>
      <p className={classes.footerText}>|</p>
      <a
        href="https://www.vertigis.com/privacy-policy/"
        className={classes.footerLink}
        target="_blank"
        rel="noreferrer"
      >
        Privacy Center
      </a>
      <p className={classes.footerText}>|</p>
      <a
        href="https://www.vertigis.com/imprint"
        className={classes.footerLink}
        target="_blank"
        rel="noreferrer"
      >
        Imprint
      </a>
    </div>
  );
}

export default CustomFooter;
