import { FilterState } from './filterSlice';

export default function buildQueryString(filter: FilterState) {
  const projects = filter.projects.map((x) => `'${x}'`).join(',');
  let queryString = `SELECT [Id] from WorkItems WHERE [System.TeamProject] IN (${projects}) AND [Custom.Changelog] = 'True' AND [System.State] = 'Closed' `;
  for (let i = 0; i < filter.conditions.length; i++) {
    queryString += `AND ${filter.conditions[i]} `;
  }
  if (!(filter.workItemType.bug && filter.workItemType.userStory)) {
    if (filter.workItemType.bug) {
      queryString += 'AND [System.WorkItemType] = "Bug" ';
    }
    if (filter.workItemType.userStory) {
      queryString += 'AND [System.WorkItemType] = "User Story" ';
    }
  }
  if (filter.dateRange.from !== -1) {
    const date = new Date(filter.dateRange.from);
    queryString += `AND [Microsoft.VSTS.Common.ClosedDate] >= '${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}T00:00Z' `;
  }
  if (filter.dateRange.to !== -1) {
    const date = new Date(filter.dateRange.to);
    queryString += `AND [Microsoft.VSTS.Common.ClosedDate] <= '${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}T00:00Z' `;
  }
  queryString += 'ORDER BY [System.Id] desc';
  return queryString;
}
