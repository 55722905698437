import React from 'react';
import { CSVLink } from 'react-csv';
import Chip from '@mui/material/Chip';
import clsx from 'clsx';
import Tooltip from '@mui/material/Tooltip';
import { WorkItem } from '../../../features/workitems/Types';
import { useStyles } from '../../../features/workitems/WorkItems.Styles';

interface Props {
  workItems: WorkItem[];
}

function extractContent(s: string) {
  const span = document.createElement('span');
  span.innerHTML = s;
  return span.textContent || span.innerText;
}

// exports a link that provides the current list as a csv download
function CSVExporter(props: Props) {
  const classes = useStyles();
  const { workItems } = props;
  const data: any = [];

  // the variable columns defines which values are written into the csv file.
  const columns = [
    'product',
    'version',
    'component',
    'title',
    'id',
    'type',
    'reportedVersion',
    'tags',
    'date',
    'developmentStatus',
    'incidentNumber',
  ];

  // adds a workitem as a row to the data variable
  const addToData = (wi: WorkItem) => {
    const row: string[] = [];
    columns.forEach((column) => row.push(wi[column as keyof WorkItem].toString()));
    row[3] = extractContent(row[3]);
    row[6] = row[6].replaceAll(';', ',');
    if (parseInt(row[8], 10) > 0) {
      row[8] = new Date(parseInt(row[8], 10)).toISOString().split('T')[0].toString();
    } else {
      row[8] = '';
    }
    data.push(row);
  };

  workItems.forEach((wi) => addToData(wi));

  return (
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    <CSVLink
      className={classes.noLinkStyle}
      filename="Changelogs_Export.csv"
      enclosingCharacter=" "
      separator=";"
      data={data}
      headers={columns}
    >
      <Tooltip title="Export current list to CSV" placement="bottom">
        <Chip
          label="Export CSV"
          className={clsx(classes.filterButton)}
          variant="outlined"
          size="small"
          color="primary"
        />
      </Tooltip>
    </CSVLink>
  );
}

export default CSVExporter;
