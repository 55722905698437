import { AppBar, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';
import CustomFooter from './footer';
import ParameterHandler from '../../features/parameters/ParameterHandler';
import HeaderLogo from './HeaderLogo';
import SearchForm from './SearchForm';

const useStyles = makeStyles((theme: Theme) => createStyles({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundImage: 'url("backdrop.png")',
    backgroundSize: 'cover',
    height: 64,
    display: 'flex',
    flexDirection: 'row-reverse',
    textAlign: 'center',
    alignItems: 'center',
  },
  title: {
    margin: 0,
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    left: '50%',
    flexGrow: 1,
    display: 'block',
  },
  root: {
    margin: 'auto',
    minHeight: 'calc(100vh - 160px)',
    marginTop: 96,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  alert: {
    position: 'fixed',
    bottom: 20,
    right: 10,
  },
  helpLink: {
    width: 35,
    paddingRight: '16px',
  },
  helpIcon: {
    width: 'inherit',
    height: 'inherit',
  },
  searchFormBorder: {
    border: '1px solid #ccc',
    borderRadius: theme.shape.borderRadius,
  },
}));

export default function StartPage() {
  const classes = useStyles();

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <div>
          <HeaderLogo link="" className={classes.title} />
          <div>
            <a
              className={classes.helpLink}
              href="https://support.vertigis.com/hc/sections/8753620022674"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                className={classes.helpIcon}
                alt="help button"
                src={`${process.env.PUBLIC_URL}/helpIcon.svg`}
              />
            </a>
          </div>
        </div>
      </AppBar>
      <div className={classes.root}>
        <div className={classes.searchFormBorder}>
          <SearchForm />
        </div>
      </div>
      <div>
        <CustomFooter />
      </div>
      <ParameterHandler />
    </>
  );
}
