import { Paper } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';

interface Props {
  children: any;
  // eslint-disable-next-line react/require-default-props
  toolbar?: any;
}

const useStyles = makeStyles(() => createStyles({
  layoutBorder: {
    marginTop: 40,
    marginLeft: 350,
    marginRight: 50,
  },
}));

export default function Layout({ children, toolbar = null }: Props) {
  const classes = useStyles();
  return (
    <div className={classes.layoutBorder}>
      {toolbar}
      <Paper variant="outlined">{children}</Paper>
    </div>
  );
}
