/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { cloneDeep } from 'lodash';
import TypeParser from './TypeParser';
import { RootState } from '../../app/store';
import { setProjectStatus, setSolutionStatus } from '../system/systemSlice';
import { WorkItem } from '../workitems/Types';
import { Search } from '../search/searchSlice';

export interface Solution {
  name: string;
  products: string[];
}

export interface Project {
  name: string;
  displayName: string;
}

export interface FilterState {
  projects: string[];
  solutions: Solution[];
  conditions: string[];
  workItemType: {
    bug: boolean;
    userStory: boolean;
  };
  valid: boolean;
  dateRange: {
    from: number;
    to: number;
  };
}

export interface Filter {
  appliedFilter: FilterState;
  currentFilter: FilterState;
  projectsIsSelected: boolean;
  availableProjects: Project[];
  availableSolutions: Solution[];
}

const initialFilterState: FilterState = {
  projects: [],
  solutions: [],
  conditions: [],
  workItemType: {
    bug: true,
    userStory: true,
  },
  valid: true,
  dateRange: {
    from: -1,
    to: -1,
  },
};

export const initialState: Filter = {
  appliedFilter: initialFilterState,
  currentFilter: cloneDeep(initialFilterState),
  availableProjects: [],
  availableSolutions: [],
  projectsIsSelected: true,
};
export const filterWorkItems = (workItems: WorkItem[], search: Search) => {
  const checkStringInArray = (array: string[], searchString: string, seperator: string = '') => {
    if (array.length === 0) {
      return true;
    }
    if (seperator !== '') {
      const searchTerms = searchString.split(seperator).map((term) => term.trim());
      return searchTerms.some(
        (term) => array.map((string) => string.trim()).includes(term.trim()) && term !== '-',
      );
    }
    return array.some((item) => item === searchString && searchString !== '-');
  };

  const getFormattedDate = (timeStamp: number) => {
    const date = new Date(timeStamp);
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
      .getDate()
      .toString()
      .padStart(2, '0')}`;
  };

  const getSearchStringFromWorkItem = (workItem: WorkItem) => {
    const formattedDate = getFormattedDate(workItem.date);
    return `${formattedDate}${workItem.component}${workItem.id}${workItem.product}${workItem.tags}${workItem.title}${workItem.type}${workItem.version}${workItem.reportedVersion}${workItem.developmentStatus}${workItem.incidentNumber}`;
  };

  return workItems.filter(
    (wi) => wi.title.toLowerCase().includes(search.title.toLowerCase().trim())
      && wi.id.toString().includes(search.id.toLowerCase().trim())
      && checkStringInArray(search.types, TypeParser.parseType(wi.type))
      && checkStringInArray(search.products, wi.productDisplayName)
      && checkStringInArray(search.components, wi.component)
      && checkStringInArray(search.versions, wi.version)
      && checkStringInArray(search.reportedVersions, wi.reportedVersion)
      && checkStringInArray(search.tags, wi.tags, ';')
      && getFormattedDate(wi.date).toLowerCase().includes(search.date.toLowerCase().trim())
      && checkStringInArray(search.devStates, wi.developmentStatus)
      && checkStringInArray(search.incidentNumbers, wi.incidentNumber, ',')
      && search.generalSearchText
        .toLowerCase()
        .split(',')
        .some((searchTerm) => getSearchStringFromWorkItem(wi).toLowerCase().includes(searchTerm.trim())),
  );
};

export const fetchProjects = createAsyncThunk('filter/fetchProjects', async (args, thunkApi) => {
  const state = thunkApi.getState() as RootState;
  if (state.filter.availableProjects.length <= 0) {
    thunkApi.dispatch(setProjectStatus('busy'));
  }
  const response = await axios.get<Project[]>('workitem/projects');
  thunkApi.dispatch(setProjectStatus('idle'));
  return response.data.sort((a, b) => a.displayName.localeCompare(b.displayName));
});

export const fetchSolutions = createAsyncThunk('filter/fetchSolutions', async (args, thunkApi) => {
  const state = thunkApi.getState() as RootState;
  if (state.filter.availableSolutions.length <= 0) {
    thunkApi.dispatch(setSolutionStatus('busy'));
  }
  const response = await axios.get<Solution[]>('workitem/solutions');
  thunkApi.dispatch(setSolutionStatus('idle'));
  return response.data;
});

export const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setWorkItemTypeBug: (state, action: PayloadAction<boolean>) => {
      state.currentFilter.workItemType.bug = action.payload;
    },
    setWorkItemTypeUserStory: (state, action: PayloadAction<boolean>) => {
      state.currentFilter.workItemType.userStory = action.payload;
    },
    setValid: (state, action: PayloadAction<boolean>) => {
      state.currentFilter.valid = action.payload;
    },
    setProjects: (state, action: PayloadAction<string[]>) => {
      state.currentFilter.projects = action.payload;
    },
    setSolutions: (state, action: PayloadAction<Solution[]>) => {
      state.currentFilter.solutions = action.payload;
    },
    setProjectSelected: (state, action: PayloadAction<boolean>) => {
      if (state.projectsIsSelected !== action.payload) {
        state.projectsIsSelected = !state.projectsIsSelected;
      }
    },
    toggleSelectedProject: (state, action: PayloadAction<string>) => {
      if (!state.currentFilter.projects.includes(action.payload)) {
        state.currentFilter.projects.push(action.payload);
      } else {
        const index = state.currentFilter.projects.findIndex((x) => x === action.payload);
        if (index !== -1) {
          state.currentFilter.projects.splice(index, 1);
        }
      }
    },
    toggleSelectedSolution: (state, action: PayloadAction<Solution>) => {
      if (!state.currentFilter.solutions.find((x) => x.name === action.payload.name)) {
        state.currentFilter.solutions.push(action.payload);
      } else {
        const index = state.currentFilter.solutions.findIndex(
          (x) => x.name === action.payload.name,
        );
        if (index !== -1) {
          state.currentFilter.solutions.splice(index, 1);
        }
      }
    },
    setProjectsFromSolution: (state) => {
      state.currentFilter.projects = [];
      state.currentFilter.solutions.map((x) => state.currentFilter.projects.push(...x.products));
      // @ts-ignore
      state.currentFilter.projects = [...new Set(state.currentFilter.projects)];
    },
    applyFilter: (state) => {
      state.appliedFilter = cloneDeep(state.currentFilter);
    },
    resetFilter: (state) => {
      state.currentFilter = cloneDeep(initialFilterState);
    },
    validate: (state) => {
      // Project and Solution validation
      const projectsValid = (state.projectsIsSelected && state.currentFilter.projects.length > 0)
        || (!state.projectsIsSelected && state.currentFilter.solutions.length > 0);

      // WorkItemType validation
      const workItemTypeValid = [state.currentFilter.workItemType.bug, state.currentFilter.workItemType.userStory].filter(
        (x) => x,
      ).length !== 0;

      // Date validation
      const fromDateValid = state.currentFilter.dateRange.from !== -2;
      const toDateValid = state.currentFilter.dateRange.to !== -2;
      const numberOfDateSelected = [
        state.currentFilter.dateRange.from,
        state.currentFilter.dateRange.to,
      ].filter((x) => x !== -1).length;
      const toDateGreaterThanFromDate = state.currentFilter.dateRange.from < state.currentFilter.dateRange.to
        || numberOfDateSelected !== 2;

      // Final validation
      state.currentFilter.valid = workItemTypeValid
        && projectsValid
        && fromDateValid
        && toDateValid
        && toDateGreaterThanFromDate;
    },
    setFromDate: (state, action: PayloadAction<number>) => {
      state.currentFilter.dateRange.from = action.payload;
    },
    setToDate: (state, action: PayloadAction<number>) => {
      state.currentFilter.dateRange.to = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjects.fulfilled, (state, action) => {
      state.availableProjects = action.payload;
    });
    builder.addCase(fetchSolutions.fulfilled, (state, action) => {
      state.availableSolutions = action.payload;
    });
  },
});

export const selectWorkItemType = (state: RootState) => state.filter.currentFilter.workItemType;
export const selectFilter = (state: RootState) => state.filter.currentFilter;
export const selectAppliedFilter = (state: RootState) => state.filter.appliedFilter;
export const selectAvailableProjects = (state: RootState) => state.filter.availableProjects;
export const selectAvailableSolutions = (state: RootState) => state.filter.availableSolutions;
export const selectProjectsIsSelected = (state: RootState) => state.filter.projectsIsSelected;
export const {
  setWorkItemTypeBug,
  setWorkItemTypeUserStory,
  setValid,
  setProjects,
  setSolutions,
  validate,
  toggleSelectedProject,
  setProjectSelected,
  toggleSelectedSolution,
  setProjectsFromSolution,
  applyFilter,
  resetFilter,
  setFromDate,
  setToDate,
} = filterSlice.actions;
export default filterSlice.reducer;
