/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import * as React from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  TablePagination,
  Skeleton,
  IconButton,
  Chip,
  Tooltip,
  Typography,
  Collapse,
  Stack,
} from '@mui/material';

import { FilterAlt, FilterAltOutlined, ManageSearch } from '@mui/icons-material';
import clsx from 'clsx';
import { useEffect, useRef } from 'react';
import WorkItemRow from './WorkItemRow';
import WorkItemTableHead, { HeadCell } from './WorkItemTableHead';
import { useAppDispatch, useAppSelector, useDeepEffect } from '../../app/hooks';
import {
  fetchPagedWorkItems,
  fetchWorkItems,
  selectTableConfig,
  selectWorkItems,
  setPage,
  setRowsPerPage,
  sortWorkItems,
} from './workItemSlice';
import { WorkItem } from './Types';
import { selectAdminView, selectStatus, toggleAdminView } from '../system/systemSlice';
import ApplicationBar from '../../app/components/navigation/ApplicationBar';
import Layout from '../../app/components/util/Layout';
import CSVExporter from '../../app/components/csvexport/csvExporter';
import {
  selectAppliedFilter,
  selectAvailableSolutions,
  selectAvailableProjects,
  selectFilter,
  applyFilter,
  setProjects,
  filterWorkItems,
} from '../filter/filterSlice';
import {
  resetSearch,
  selectNoFilterActive,
  selectSearch,
  toggleShowFilter,
} from '../search/searchSlice';
import ParameterHandler from '../../features/parameters/ParameterHandler';
import { useStyles } from './WorkItems.Styles';
import GeneratePdf from '../../app/components/pdfexport/PdfGenerator';

const headCells: HeadCell<WorkItem>[] = [
  {
    id: 'productDisplayName',
    align: 'left',
    disablePadding: false,
    label: 'Product',
    sortingName: 'System.TeamProject',
  },
  {
    id: 'version',
    align: 'left',
    disablePadding: false,
    label: 'Version',
    sortingName: 'System.IterationPath',
  },
  {
    id: 'component',
    align: 'left',
    disablePadding: false,
    label: 'Component',
    sortingName: 'System.TeamProject',
  },
  {
    id: 'title',
    align: 'left',
    disablePadding: false,
    label: 'Title',
    sortingName: 'Custom.PublicTitle',
    disableSorting: true,
  },
  {
    id: 'id',
    align: 'left',
    disablePadding: false,
    label: 'ID',
    sortingName: 'System.Id',
  },
  {
    id: 'type',
    align: 'left',
    disablePadding: false,
    label: 'Type',
    sortingName: 'System.WorkItemType',
  },
  {
    id: 'reportedVersion',
    align: 'left',
    disablePadding: false,
    label: 'Reported Version',
    sortingName: 'Microsoft.VSTS.Build.FoundIn',
  },
  {
    id: 'tags',
    align: 'center',
    disablePadding: false,
    label: 'Tags',
    sortingName: 'System.Tags',
    disableSorting: true,
  },
  {
    id: 'date',
    align: 'left',
    disablePadding: false,
    label: 'Release Date',
    sortingName: 'Microsoft.VSTS.Common.ClosedDate',
  },
  {
    id: 'developmentStatus',
    align: 'left',
    disablePadding: false,
    label: 'State',
    sortingName: 'System.State',
  },
  {
    id: 'incidentNumber',
    align: 'left',
    disablePadding: false,
    label: 'IncidentNumber',
    sortingName: 'Custom.IncidentNumber',
  },
];

const skeletonHeight = 20;

export default function WorkItems() {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const workItems = useAppSelector(selectWorkItems);
  const systemStatus = useAppSelector(selectStatus);
  const adminView = useAppSelector(selectAdminView);
  const filter = useAppSelector(selectAppliedFilter);
  const filterState = useAppSelector(selectFilter);
  const { page, rowsPerPage } = useAppSelector(selectTableConfig);
  const search = useAppSelector(selectSearch);
  const tableBodyRef = useRef<HTMLDivElement | null>(null);
  const [displayData, setDisplayData] = React.useState(false);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setPage(0));
  };

  const onAdminViewClick = () => {
    dispatch(toggleAdminView());
  };
  const availableSolutions = useAppSelector(selectAvailableSolutions);
  const availableProjects = useAppSelector(selectAvailableProjects);

  React.useEffect(() => {
    if (filter.projects.length < 1) {
      dispatch(setProjects(availableProjects.map((x) => x.name)));
      dispatch(applyFilter());
    }
  }, [availableProjects.length]);

  const allSolutionsSelected = React.useMemo(
    () => availableSolutions.every((x) => filterState.solutions.includes(x)),
    [filterState.solutions.length, availableSolutions.length],
  );

  const allProjectsSelected = React.useMemo(
    () => availableProjects.every((x) => filterState.projects.includes(x.name)),
    [filterState.projects.length, availableProjects.length],
  );

  const selectAllProjects = React.useCallback(() => {
    dispatch(setProjects(availableProjects.map((x) => x.name)));
    dispatch(applyFilter());
  }, []);

  const selectAllSolutions = React.useCallback(() => {
    dispatch(setProjects(availableSolutions.map((x) => x.name)));
    dispatch(applyFilter());
  }, []);

  const noFilterActive = useAppSelector(selectNoFilterActive);
  useDeepEffect(() => {
    setDisplayData(false);
    dispatch(
      fetchWorkItems({
        filter,
      }),
    ).then(() => dispatch(sortWorkItems()));
    setDisplayData(true);
  }, [filter]);

  const renderSkeletonRow = (key: number) => (
    <TableRow key={key}>
      <TableCell width="14%">
        <Skeleton height={skeletonHeight} />
      </TableCell>
      <TableCell width="3%">
        <Skeleton height={skeletonHeight} />
      </TableCell>
      <TableCell width="10%">
        <Skeleton height={skeletonHeight} />
      </TableCell>
      <TableCell width="60%">
        <Skeleton height={skeletonHeight} />
      </TableCell>
      <TableCell width="5%">
        <Skeleton height={skeletonHeight} />
      </TableCell>
      <TableCell width="5%">
        <Skeleton height={skeletonHeight} />
      </TableCell>
      <TableCell width="5%">
        <Skeleton height={skeletonHeight} />
      </TableCell>
      <TableCell width="4%">
        <Skeleton height={skeletonHeight} />
      </TableCell>
      <TableCell width="5%">
        <Skeleton height={skeletonHeight} />
      </TableCell>
      <TableCell width="5%">
        <Skeleton height={skeletonHeight} />
      </TableCell>
      <TableCell width="7%">
        <Skeleton height={skeletonHeight} />
      </TableCell>
      <TableCell width="5%">
        <Skeleton height={skeletonHeight} />
      </TableCell>
    </TableRow>
  );

  const filteredWorkItems = filterWorkItems(workItems, search);
  const handleChangePage = (event: unknown, newPage: number) => {
    if (rowsPerPage * newPage + rowsPerPage >= filteredWorkItems.length) {
      dispatch(fetchPagedWorkItems({ filter }));
    }
    dispatch(setPage(newPage));
  };

  // Resets the scroll bar position to the beginning on filter change
  useEffect(() => {
    if (tableBodyRef.current) {
      tableBodyRef.current.scrollTop = 0;
    }
  }, [filteredWorkItems]);

  //    emptyRows exists for when the rowsPerPage is set to 20/50, but there are less than 20/50 workItems.
  //    with emtyRows the total number of rows will always be at least 10.

  const emptyRows = (() => {
    if (filteredWorkItems.length >= 10) {
      return 0;
    }
    return 10 - filteredWorkItems.length;
  })();

  //   const { width } = useWindowSize();

  const renderRow = () => {
    if (systemStatus.workItems !== 'busy') {
      if (filteredWorkItems.length < 1) {
        return (
          <TableRow style={{ height: 33 * rowsPerPage }}>
            <TableCell colSpan={10} className={classes.noResultsCell}>
              <Typography className={classes.noResultsText}>No results ...</Typography>
              <ManageSearch className={classes.noResultsIcon} />
            </TableCell>
          </TableRow>
        );
      }
      return (
        <>
          {filteredWorkItems
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((wi) => (
              <WorkItemRow key={wi.id} wi={wi} displayData={displayData} />
            ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 37 * emptyRows }}>
              <TableCell colSpan={10} />
            </TableRow>
          )}
        </>
      );
    }
    return Array.from({ length: rowsPerPage }, (_, i) => i + 1).map(renderSkeletonRow);
  };
  return (
    <ApplicationBar>
      <Layout
        toolbar={(
          <div className={classes.iconContainer}>
            <IconButton
              className={classes.filterButton}
              onClick={() => dispatch(toggleShowFilter())}
            >
              {search.showFilter ? <FilterAlt /> : <FilterAltOutlined />}
            </IconButton>
            <Collapse
              orientation="horizontal"
              className={classes.collapse}
              collapsedSize="0px"
              in={!noFilterActive && !search.showFilter}
            >
              <Tooltip title="Click 'x' to reset filter" placement="bottom">
                <Chip
                  className={classes.chip}
                  label="Filter active"
                  variant="outlined"
                  size="small"
                  color="primary"
                  onDelete={() => dispatch(resetSearch())}
                />
              </Tooltip>
            </Collapse>
            <Collapse
              orientation="horizontal"
              className={classes.collapse}
              collapsedSize="0px"
              in={!allProjectsSelected && !search.showFilter}
            >
              <Tooltip title="Click 'x' to reset projects" placement="bottom">
                <Chip
                  className={classes.chip}
                  label="Product filter active"
                  variant="outlined"
                  size="small"
                  color="primary"
                  onDelete={() => selectAllProjects()}
                />
              </Tooltip>
            </Collapse>
            <Collapse
              orientation="horizontal"
              className={classes.collapse}
              collapsedSize="0px"
              in={!allSolutionsSelected && !search.showFilter}
            >
              <Tooltip title="Click 'x' to reset solutions" placement="bottom">
                <Chip
                  className={classes.chip}
                  label="Solution filter active"
                  variant="outlined"
                  size="small"
                  color="primary"
                  onDelete={() => selectAllSolutions()}
                />
              </Tooltip>
            </Collapse>

            <div className={classes.right}>
              <Stack spacing={1} direction="row">
                <Tooltip
                  title={
                    adminView
                      ? 'Click to diable ID links'
                      : 'Click to enable ID links - Requires VertiGIS DevOps access'
                  }
                  placement="bottom"
                >
                  <Chip
                    className={clsx(classes.filterButton)}
                    label={adminView ? 'see public view' : 'activate ID link'}
                    variant="outlined"
                    size="small"
                    color="primary"
                    onClick={() => onAdminViewClick()}
                  />
                </Tooltip>

                <Tooltip title="Export current list to PDF" placement="bottom">
                  <Chip
                    className={clsx(classes.filterButton)}
                    label="Export PDF"
                    variant="outlined"
                    size="small"
                    color="primary"
                    onClick={() => GeneratePdf(filteredWorkItems)}
                  />
                </Tooltip>
                <CSVExporter workItems={filteredWorkItems} />
              </Stack>
            </div>
          </div>
        )}
      >
        <div>
          {/* for future mobile version: {width > 600 ? ( */}
          <>
            <TableContainer ref={tableBodyRef} className={classes.tableContent}>
              <Table size="small">
                <WorkItemTableHead classes={classes} headCells={headCells} />
                <TableBody>{renderRow()}</TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              classes={classes}
              rowsPerPageOptions={[10, 20, 50]}
              component="div"
              count={filteredWorkItems.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
          {/* ) : (
            <>
              {filteredWorkItems
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((wi) => (
                  <>
                    <WorkItemDetail key={wi.id} wi={wi} />
                    <div style={{ marginBottom: 10 }} />
                  </>
                ))}
            </>
          )} */}
        </div>
      </Layout>
      <ParameterHandler />
    </ApplicationBar>
  );
}
