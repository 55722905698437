/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { cloneDeep } from 'lodash';
import { WorkItem } from './Types';
import { RootState } from '../../app/store';
import { setWorkitemStatus } from '../system/systemSlice';
import { getComparator, stableSort } from '../sorting/sorting';
import buildQueryString from '../filter/functions';
import { FilterState } from '../filter/filterSlice';

export interface WorkItemState {
  workItems: WorkItem[];
  lastId: number;
  tableConfig: {
    page: number;
    rowsPerPage: number;
  };
}

export const initialState: WorkItemState = {
  workItems: [],
  lastId: -1,
  tableConfig: {
    page: 0,
    rowsPerPage: 10,
  },
};

const fetchWorkItemsFunc = async (
  args: { filter: FilterState },
  thunkApi: any,
  setBusy: boolean,
) => {
  if (setBusy) thunkApi.dispatch(setWorkitemStatus('busy'));
  const { filter } = args;

  if (filter.projects.length < 1) return { workItems: [], lastId: -1 };

  const rootState = thunkApi.getState() as RootState;
  const { orderBy } = rootState.sorting;
  const { workItems } = rootState.workItems;
  const queryString = buildQueryString(filter);

  try {
    const response = await axios.post<{ workItems: WorkItem[]; lastId: number }>('workitem', {
      query: queryString,
    });
    thunkApi.dispatch(setWorkitemStatus('idle'));
    return response.data;
  } catch (e) {
    thunkApi.dispatch(setWorkitemStatus('idle'));
    throw e;
  }
};

export const fetchWorkItems = createAsyncThunk(
  'workItems/fetchWorkItems',
  (args: { filter: FilterState }, thunkApi) => fetchWorkItemsFunc(args, thunkApi, true),
);

export const fetchPagedWorkItems = createAsyncThunk(
  'workItems/fetchPagedWorkItems',
  (args: { filter: FilterState }, thunkApi) => fetchWorkItemsFunc(args, thunkApi, false),
);

export const sortWorkItems = createAsyncThunk('workItems/sortWorkItems', async (args, thunkApi) => {
  const state = thunkApi.getState() as RootState;
  const newItems = cloneDeep(state.workItems.workItems);
  const { order, orderBy } = state.sorting;
  return stableSort<WorkItem>(newItems, getComparator(order, orderBy as keyof WorkItem));
});

export const workItemSlice = createSlice({
  name: 'workItems',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.tableConfig.page = action.payload;
    },
    setRowsPerPage: (state, action: PayloadAction<number>) => {
      state.tableConfig.rowsPerPage = action.payload;
    },
    clearWorkItems: (state) => {
      state.workItems = [];
      state.lastId = -1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPagedWorkItems.fulfilled, (state, action) => {
      action.payload.workItems.forEach((w) => {
        if (state.workItems.findIndex((x) => x.id === w.id) < 0) {
          state.workItems.push(w);
        }
      });
      state.lastId = action.payload.lastId;
    });
    builder.addCase(fetchWorkItems.fulfilled, (state, action) => {
      state.workItems = action.payload.workItems;
      state.lastId = action.payload.lastId;
    });
    builder.addCase(sortWorkItems.fulfilled, (state, action) => {
      state.workItems = [];
      for (let i = 0; i < action.payload.length; i++) {
        state.workItems.push(action.payload[i]);
      }
    });
  },
});

export const getFilteredTags = (fullWorkItems: WorkItem[]) => {
  const fullTags = fullWorkItems.filter((x) => x.tags !== '-' && x.useTags).map((x) => x.tags);
  const splittedTags: string[] = [];
  fullTags.forEach((x) => {
    const tagsOfWorkItem = x.split(';');
    tagsOfWorkItem.forEach((y) => {
      const trimmedTag = y.trim();
      let tagAlreadyExists = false;
      splittedTags.forEach((z) => {
        if (trimmedTag === z) {
          tagAlreadyExists = true;
        }
      });
      if (!tagAlreadyExists) {
        splittedTags.push(trimmedTag);
      }
    });
  });
  splittedTags.sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1));
  return splittedTags;
};

export const selectWorkItems = (state: RootState) => state.workItems.workItems;

export const selectTableConfig = (state: RootState) => state.workItems.tableConfig;

export const { setPage, setRowsPerPage, clearWorkItems } = workItemSlice.actions;

export default workItemSlice.reducer;
