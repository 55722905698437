/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export type Status = 'idle' | 'busy';
export interface SystemState {
  status: {
    workItems: Status;
    projects: Status;
    solutions: Status;
  };
  adminView: boolean;
}

export const initialState: SystemState = {
  status: {
    workItems: 'idle',
    projects: 'idle',
    solutions: 'idle',
  },
  adminView: false,
};

export const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    setWorkitemStatus: (state, action: PayloadAction<Status>) => {
      state.status.workItems = action.payload;
    },
    setProjectStatus: (state, action: PayloadAction<Status>) => {
      state.status.projects = action.payload;
    },
    setSolutionStatus: (state, action: PayloadAction<Status>) => {
      state.status.solutions = action.payload;
    },
    toggleAdminView: (state) => {
      state.adminView = !state.adminView;
    },
  },
});

export const {
  setWorkitemStatus, setProjectStatus, setSolutionStatus, toggleAdminView,
} = systemSlice.actions;

export const selectStatus = (state: RootState) => state.system.status;
export const selectAdminView = (state: RootState) => state.system.adminView;

export default systemSlice.reducer;
