import {
  useMediaQuery,
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
  // @ts-ignore
  PaletteType,
  adaptV4Theme,
} from '@mui/material';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import axios from 'axios';
import { useAppSelector } from './app/hooks';
import { selectTheme, Theme } from './features/theme/themeSlice';
import WorkItems from './features/workitems/WorkItems';
import NotFound from './app/components/navigation/NotFound';
import StartPage from './app/components/StartPage';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  // eslint-disable-next-line no-unused-vars
  interface DefaultTheme extends Theme {}
}

axios.interceptors.request.use((config) => {
  if (process.env.REACT_APP_SPA_PROXY_PORT) {
    return {
      ...config,
      proxy: {
        host: '.',
        port: parseInt(process.env.REACT_APP_SPA_PROXY_PORT, 10),
      },
    };
  }
  return config;
});

export default function App() {
  const prefersDarkmode = useMediaQuery('(prefers-color-scheme: dark)');

  const setTheme: Theme = useAppSelector(selectTheme);

  let finalTheme = 'nothing';
  if (setTheme.mode === 'system') {
    finalTheme = prefersDarkmode ? 'dark' : 'light';
  } else {
    finalTheme = setTheme.mode;
  }
  const theme = createTheme(
    adaptV4Theme({
      palette: {
        mode: finalTheme as PaletteType,
        primary: {
          main: '#138943',
          dark: '#138943',
        },
      },
      typography: {
        fontFamily: [
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
      },
    }),
  );

  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Switch>
            <Route path="/" exact component={StartPage} />
            <Route path="/workitems" component={WorkItems} />
            <Route path="/" component={NotFound} />
          </Switch>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
}
