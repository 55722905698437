import * as React from 'react';
import {
  Autocomplete,
  Chip,
  Collapse,
  Grow,
  IconButton,
  Paper,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Clear } from '@mui/icons-material';
import BugIcon from '@mui/icons-material/BugReport';
import FeatureIcon from '@mui/icons-material/MenuBook';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  clearSort,
  selectOrder,
  selectOrderBy,
  setOrder,
  setOrderBy,
  setOrderByTechnical,
} from '../sorting/sortingSlice';
import { WorkItem } from './Types';
import {
  resetSearch,
  selectNoFilterActive,
  selectSearch,
  setComponents,
  setDate,
  setDevStates,
  setId,
  setIncidentNumbers,
  setProducts,
  setReportedVersion,
  setTags,
  setTitle,
  setTypes,
  setVersions,
} from '../search/searchSlice';
import {
  selectWorkItems, setPage, sortWorkItems, getFilteredTags,
} from './workItemSlice';
import TypeParser from '../filter/TypeParser';
import { selectAvailableProjects, setProjects } from '../filter/filterSlice';

export interface HeadCell<T> {
  disablePadding: boolean;
  id: keyof T;
  label: string;
  align: 'left' | 'right' | 'center' | 'justify' | undefined;
  span?: number;
  toolTip?: any;
  sortingName: string;
  disableSorting?: boolean;
}

interface TableHeadProps<T> {
  classes: any;
  headCells: HeadCell<T>[];
}

const useStyles = makeStyles(() => createStyles({
  headCell: {
    borderBottom: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
  filterRow: {
    '& th': {
      '& div': {
        '& div': {
          '& div': {
            fontSize: 'small',
          },
        },
      },
      paddingTop: 0,
    },
  },
  option: {
    fontSize: 'small',
  },
  titleSearch: {
    width: '100%',
  },
}));

export default function WorkItemTableHead<T>(props: TableHeadProps<T>) {
  const { classes, headCells } = props;
  const localClasses = useStyles();
  const search = useAppSelector(selectSearch);

  const order = useAppSelector(selectOrder);
  const orderBy = useAppSelector(selectOrderBy);
  const availableProjects = useAppSelector(selectAvailableProjects);

  const dispatch = useAppDispatch();

  const workItems = useAppSelector(selectWorkItems);

  const noFilterActive = useAppSelector(selectNoFilterActive);

  const onSort = (property: keyof T, sortingName: string) => {
    const isAsc = orderBy === property && order === 'asc';

    const newOrder = isAsc ? 'desc' : 'asc';

    dispatch(setOrder(newOrder));
    dispatch(setOrderBy(property as keyof WorkItem));
    dispatch(setOrderByTechnical(sortingName));
    dispatch(setPage(0));
    dispatch(sortWorkItems());
  };

  const onFilter = (func: () => void) => {
    dispatch(setPage(0));
    func();
  };
  const renderAutocompleteTags = (value: readonly string[], getTagProps: any) => value.map((option: string, index: number) => (
    <Tooltip key={option} title={option} placement="top">
      <Chip
        size="small"
        variant="outlined"
        color="primary"
        label={option.length > 10 ? `${option.substring(0, 10)}...` : option}
          // eslint-disable-next-line react/jsx-props-no-spreading
        {...getTagProps({ index })}
      />
    </Tooltip>
  ));
  const CustomAutocomplete = ({
    options,
    onChange,
    value,
    renderTags = renderAutocompleteTags,
    renderOption,
  }: any) => (
    <Autocomplete
      classes={localClasses}
      disablePortal
      multiple
      filterSelectedOptions
      renderTags={renderTags}
      freeSolo
      id="combo-box-demo"
      fullWidth
      PaperComponent={({ children }) => <Paper style={{ width: 'fit-content' }}>{children}</Paper>}
      options={options}
      onChange={onChange}
      value={value}
      renderOption={renderOption}
      // eslint-disable-next-line react/jsx-props-no-spreading
      renderInput={(params) => <TextField {...params} variant="standard" />}
    />
  );

  const returnFilteredIncidentNumbers = () => {
    const incidentNumbers: String[] = [];

    workItems
      .filter((x) => x.incidentNumber !== '-')
      .map((x) => x.incidentNumber)
      .forEach((fullIn) => {
        fullIn.split(',').forEach((subIn) => {
          if (incidentNumbers.indexOf(subIn) === -1) incidentNumbers.push(subIn);
        });
      });

    return incidentNumbers.sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1));
  };

  return (
    <TableHead
      style={{
        position: 'sticky',
        top: 0,
        background: 'white',
        zIndex: 2,
      }}
    >
      <TableRow>
        <TableCell size="small" className={localClasses.headCell} />
        {headCells.map((cell) => (
          <TableCell
            size="small"
            className={localClasses.headCell}
            key={cell.id.toString()}
            align={cell.align}
            padding={cell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === cell.id ? order : 'asc'}
            colSpan={cell.span ?? 1}
          >
            <TableSortLabel
              disabled={cell.disableSorting}
              active={!cell.disableSorting && orderBy === cell.id}
              direction={orderBy === cell.id ? order : 'asc'}
              onClick={cell.disableSorting ? () => {} : () => onSort(cell.id, cell.sortingName)}
            >
              <b>{cell.label}</b>
              {orderBy === cell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell className={localClasses.headCell} size="small">
          <Grow in={!(orderBy === 'date' && order === 'desc')}>
            <Tooltip title={<Typography variant="body1">Reset ordering</Typography>}>
              <IconButton onClick={() => dispatch(clearSort())} size="large">
                <Clear />
              </IconButton>
            </Tooltip>
          </Grow>
        </TableCell>
      </TableRow>
      <TableRow className={localClasses.filterRow} style={{ verticalAlign: 'bottom' }}>
        <TableCell>
          <Collapse in={search.showFilter} />
        </TableCell>
        <TableCell>
          <Collapse in={search.showFilter}>
            <CustomAutocomplete
              value={search.products}
              onChange={(event: any, newValue: any) => {
                if (newValue != null) {
                  onFilter(() => {
                    const NamesOfSelectedProjects: string[] = [];
                    newValue.forEach((x: string) => {
                      const selectedProject = availableProjects.find((y) => y.displayName === x);
                      if (selectedProject !== undefined) {
                        NamesOfSelectedProjects.push(selectedProject.name);
                      }
                    });
                    dispatch(setProducts([...newValue]));
                    dispatch(setProjects([...NamesOfSelectedProjects]));
                  });
                }
              }}
              options={[
                // @ts-ignore
                ...new Set(
                  workItems
                    .filter((x) => x.product !== '-')
                    .map((x) => x.productDisplayName)
                    .sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1)),
                ),
              ]}
            />
          </Collapse>
        </TableCell>
        <TableCell>
          <Collapse in={search.showFilter}>
            <CustomAutocomplete
              value={search.versions}
              onChange={(event: any, newValue: any) => {
                if (newValue != null) {
                  onFilter(() => dispatch(setVersions([...newValue])));
                }
              }}
              options={[
                // @ts-ignore
                ...new Set(
                  workItems
                    .filter((x) => x.version !== '-')
                    .map((x) => x.version)
                    .sort(),
                ),
              ]}
            />
          </Collapse>
        </TableCell>
        <TableCell>
          <Collapse in={search.showFilter}>
            <CustomAutocomplete
              value={search.components}
              onChange={(event: any, newValue: any) => {
                if (newValue != null) {
                  onFilter(() => dispatch(setComponents([...newValue])));
                }
              }}
              options={[
                // @ts-ignore
                ...new Set(
                  workItems
                    .filter((x) => x.component !== '-')
                    .map((x) => x.component)
                    .sort(),
                ),
              ]}
            />
          </Collapse>
        </TableCell>
        <TableCell>
          <Collapse in={search.showFilter}>
            <Autocomplete
              classes={localClasses}
              disablePortal
              freeSolo
              id="combo-box-demo"
              value={search.title}
              onInputChange={(event, newValue) => onFilter(() => dispatch(setTitle(newValue ?? '')))}
              options={[]}
              fullWidth
              PaperComponent={({ children }) => (
                <Paper style={{ width: 'fit-content' }}>{children}</Paper>
              )}
              // eslint-disable-next-line react/jsx-props-no-spreading
              renderInput={(params) => <TextField {...params} variant="standard" />}
            />
          </Collapse>
        </TableCell>
        <TableCell>
          <Collapse in={search.showFilter}>
            <Autocomplete
              classes={localClasses}
              disablePortal
              freeSolo
              id="combo-box-demo"
              value={search.id}
              onInputChange={(event, newValue) => onFilter(() => dispatch(setId(newValue ?? '')))}
              options={[]}
              fullWidth
              PaperComponent={({ children }) => (
                <Paper style={{ width: 'fit-content' }}>{children}</Paper>
              )}
              // eslint-disable-next-line react/jsx-props-no-spreading
              renderInput={(params) => <TextField {...params} variant="standard" />}
            />
          </Collapse>
        </TableCell>
        <TableCell>
          <Collapse in={search.showFilter}>
            <CustomAutocomplete
              // eslint-disable-next-line react/prop-types
              renderTags={(value: readonly string[], getTagProps: any) => value.map((option: string, index: number) => (
                <Chip
                  key={option}
                  size="small"
                  variant="outlined"
                  color="primary"
                  icon={
                      // eslint-disable-next-line no-nested-ternary
                      option.toLowerCase().includes('added') ? (
                        <FeatureIcon sx={{ color: '#000000' }} />
                      ) : option.toLowerCase().includes('fixed') ? (
                        <BugIcon sx={{ color: '#000000' }} />
                      ) : undefined
                    }
                  label={option.toLowerCase().includes('added') ? 'Feature added' : 'Bug fixed'}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                  {...getTagProps({ index })}
                />
              ))}
              value={search.types}
              onChange={(event: any, newValue: any) => {
                if (newValue != null) {
                  onFilter(() => dispatch(setTypes([...newValue])));
                }
              }}
              options={[
                // @ts-ignore
                ...new Set(
                  workItems
                    .filter((x) => x.type !== '-')
                    .map((x) => TypeParser.parseType(x.type))
                    .sort(),
                ),
              ]}
              renderOption={(p: any, option: any) => {
                console.warn(p, option, option.indexOf('Fixed'));
                return (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <li {...p}>{option.indexOf('Fixed') > -1 ? <BugIcon /> : <FeatureIcon />}</li>
                );
              }}
            />
          </Collapse>
        </TableCell>
        <TableCell>
          <Collapse in={search.showFilter}>
            <CustomAutocomplete
              value={search.reportedVersions}
              onChange={(event: any, newValue: any) => {
                if (newValue != null) {
                  onFilter(() => dispatch(setReportedVersion([...newValue])));
                }
              }}
              options={[
                // @ts-ignore
                ...new Set(
                  workItems
                    .filter((x) => x.reportedVersion !== '-')
                    .map((x) => x.reportedVersion)
                    .sort(),
                ),
              ]}
            />
          </Collapse>
        </TableCell>
        <TableCell>
          <Collapse in={search.showFilter}>
            <CustomAutocomplete
              value={search.tags}
              onChange={(event: any, newValue: any) => {
                if (newValue != null) onFilter(() => dispatch(setTags([...newValue])));
              }}
              options={getFilteredTags(workItems)}
            />
          </Collapse>
        </TableCell>
        <TableCell>
          <Collapse in={search.showFilter}>
            <Autocomplete
              classes={localClasses}
              disablePortal
              freeSolo
              id="combo-box-demo"
              value={search.date}
              onInputChange={(event, newValue) => onFilter(() => dispatch(setDate(newValue ?? '')))}
              options={[]}
              fullWidth
              PaperComponent={({ children }) => (
                <Paper style={{ width: 'fit-content' }}>{children}</Paper>
              )}
              // eslint-disable-next-line react/jsx-props-no-spreading
              renderInput={(params) => <TextField {...params} variant="standard" />}
            />
          </Collapse>
        </TableCell>
        <TableCell>
          <Collapse in={search.showFilter}>
            <CustomAutocomplete
              value={search.devStates}
              onChange={(event: any, newValue: any) => {
                if (newValue != null) {
                  onFilter(() => dispatch(setDevStates([...newValue])));
                }
              }}
              options={[
                // @ts-ignore
                ...new Set(
                  workItems
                    .filter((x) => x.developmentStatus !== '-')
                    .map((x) => x.developmentStatus)
                    .sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1)),
                ),
              ]}
            />
          </Collapse>
        </TableCell>
        <TableCell>
          <Collapse in={search.showFilter}>
            <CustomAutocomplete
              value={search.incidentNumbers}
              onChange={(event: any, newValue: any) => {
                if (newValue != null) {
                  onFilter(() => dispatch(setIncidentNumbers([...newValue])));
                }
              }}
              options={[
                // @ts-ignore
                ...new Set(returnFilteredIncidentNumbers()),
              ]}
            />
          </Collapse>
        </TableCell>
        <TableCell>
          <Collapse in={search.showFilter}>
            <Grow in={!noFilterActive}>
              <Tooltip title={<Typography variant="body1">Reset filter</Typography>}>
                <IconButton onClick={() => onFilter(() => dispatch(resetSearch()))} size="large">
                  <Clear />
                </IconButton>
              </Tooltip>
            </Grow>
          </Collapse>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
