import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import themeReducer from '../features/theme/themeSlice';
import searchReducer from '../features/search/searchSlice';
import sortingReducer from '../features/sorting/sortingSlice';
import workItemReducer from '../features/workitems/workItemSlice';
import systemReducer from '../features/system/systemSlice';
import filterReducer from '../features/filter/filterSlice';

export const store = configureStore({
  reducer: {
    theme: themeReducer,
    search: searchReducer,
    sorting: sortingReducer,
    workItems: workItemReducer,
    system: systemReducer,
    filter: filterReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
