/* eslint-disable no-unused-vars */
import * as React from 'react';
import {
  Alert, Grow, AlertTitle, IconButton,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import {
  selectAvailableProjects,
  setProjects,
  selectAvailableSolutions,
  setProjectsFromSolution,
  setSolutions,
  applyFilter,
  setToDate,
  setFromDate,
  setWorkItemTypeUserStory,
  setWorkItemTypeBug,
  fetchProjects,
  selectFilter,
} from '../../features/filter/filterSlice';
import { useAppDispatch, useAppSelector, useQuery } from '../../app/hooks';
import {
  selectSearch,
  setComponents,
  setDevStates,
  setGeneralSearchText,
  setId,
  setIncidentNumbers,
  setProducts,
  setReportedVersion,
  setTags,
  setDate,
  setTitle,
  setTypes,
  setVersions,
} from '../search/searchSlice';
// This component handles the url parameters.
// This component reads the url parameters and sets the filters accordingly.
// In addition, it sets the url parameters depending on which filters have been set.
function ParameterHandler() {
  const [error, setError] = React.useState('');
  const availableProjects = useAppSelector(selectAvailableProjects);
  const availableSolutions = useAppSelector(selectAvailableSolutions);
  const search = useAppSelector(selectSearch);
  const query = useQuery();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const filter = useAppSelector(selectFilter);
  const solutionNames = availableSolutions.map((s) => s.name);
  const product = query.get('product')?.split(';');
  const version = query.get('version')?.split(';');
  const component = query.get('component')?.split(';');
  const title = query.get('title');
  const id = query.get('id');
  const type = query.get('type')?.split(';');
  const reportedVersion = query.get('reportedversion')?.split(';');
  const tag = query.get('tag')?.split(';');
  const releasedate = query.get('releasedate');
  const state = query.get('state')?.split(';');
  const incidentNumber = query.get('incidentnumber')?.split(';');
  const solution = query.get('solution')?.split(';');
  const searchParam = query.get('search');
  const dateFrom = query.get('datefrom');
  const dateTo = query.get('dateto');

  // checks if the url has parameters.
  const HasParameters = () => {
    if (
      product == null
      && version == null
      && component == null
      && title == null
      && id == null
      && type == null
      && reportedVersion == null
      && tag == null
      && releasedate == null
      && state == null
      && incidentNumber == null
      && solution == null
      && searchParam == null
      && dateFrom == null
      && dateTo == null
    ) {
      return false;
    }
    return true;
  };

  // checks if the filter has settings
  const HasFilterSettings = () => {
    if (
      filter.projects.length > 0
      || search.versions.length > 0
      || search.components.length > 0
      || search.title.length > 0
      || search.id.length > 0
      || search.types.length > 0
      || search.reportedVersions.length > 0
      || search.tags.length > 0
      || search.date.length > 0
      || search.devStates.length > 0
      || search.incidentNumbers.length > 0
      || search.generalSearchText.length > 0
      || filter.dateRange.from > -1
      || filter.dateRange.to > -1
    ) {
      return true;
    }
    return false;
  };

  // handles a change on the filter to set the parameters.
  React.useEffect(() => {
    if (HasFilterSettings()) {
      history.push({
        search: `${new URLSearchParams({
          ...(filter.projects.length > 0
            && filter.projects.length < availableProjects.length && {
            product: filter.projects.join(';'),
          }),
          ...(search.versions.length > 0 && { version: search.versions.join(';') }),
          ...(search.components.length > 0 && { component: search.components.join(';') }),
          ...(search.title.length > 0 && { title: search.title }),
          ...(search.id.length > 0 && { id: search.id }),
          ...(search.types.length > 0 && { type: search.types.join(';') }),
          ...(search.reportedVersions.length > 0 && {
            reportedversion: search.reportedVersions.join(';'),
          }),
          ...(search.tags.length > 0 && { tag: search.tags.join(';') }),
          ...(search.date.length > 0 && { releasedate: search.date }),
          ...(search.devStates.length > 0 && { state: search.devStates.join(';') }),
          ...(search.incidentNumbers.length > 0 && {
            incidentnumber: search.incidentNumbers.join(';'),
          }),
          ...(search.generalSearchText.length > 0 && { search: search.generalSearchText }),
          ...(filter.dateRange.from > 0 && {
            datefrom: new Date(filter.dateRange.from).toISOString(),
          }),
          ...(filter.dateRange.to > 0 && {
            dateto: new Date(filter.dateRange.to).toISOString(),
          }),
        }).toString()}`,
      });
    }
  }, [search, filter.dateRange, filter.projects, availableProjects.length]);

  // handles a parameterized-URL-call
  // This useEffect sets the filter depending on what the parameters are.
  React.useEffect(() => {
    if (HasParameters() && availableProjects.length > 0) {
      if (product !== undefined && solution !== undefined) {
        setError('Solution and Product should not both be defined.');
        return;
      }
      if (product !== undefined && product.length > 0) {
        const NamesOfSelectedProjects: string[] = [];
        product.forEach((project) => {
          const selectedProject = availableProjects.find((x) => x.name === project);
          if (selectedProject !== undefined) {
            NamesOfSelectedProjects.push(selectedProject.displayName);
          }
        });
        dispatch(setProducts([...NamesOfSelectedProjects]));
        dispatch(setProjects([...product]));
      } else {
        dispatch(setProjects(availableProjects.map((x) => x.displayName)));
        dispatch(setProjects(availableProjects.map((x) => x.name)));
      }
      if (version !== undefined && version.length > 0) {
        dispatch(setVersions([...version]));
      }
      if (component !== undefined && component.length > 0) {
        dispatch(setComponents([...component]));
      }
      if (title !== undefined && title !== null && title.length > 0) {
        dispatch(setTitle(title));
      }
      if (id !== undefined && id !== null && id.length > 0) {
        dispatch(setId(id));
      }
      if (type !== undefined && type.length > 0) {
        dispatch(setTypes([...type]));
      }
      if (reportedVersion !== undefined && reportedVersion.length > 0) {
        dispatch(setReportedVersion([...reportedVersion]));
      }
      if (tag !== undefined && tag.length > 0) {
        dispatch(setTags([...tag]));
      }
      if (releasedate !== undefined && releasedate) {
        dispatch(setDate(releasedate));
      }
      if (state !== undefined && state.length > 0) {
        dispatch(setDevStates([...state]));
      }
      if (incidentNumber !== undefined && incidentNumber.length > 0) {
        dispatch(setIncidentNumbers([...incidentNumber]));
      }

      if (solution?.every((s) => solutionNames.includes(s))) {
        dispatch(setSolutions(availableSolutions.filter((s) => solution.includes(s.name))));
        dispatch(setProjectsFromSolution());
      } else if (solution !== undefined && solution.length > 0) {
        setError(`Invalid solutions: ${solution?.filter((s) => !solutionNames.includes(s))}`);
        return;
      }

      if (searchParam != null) dispatch(setGeneralSearchText(searchParam));

      if (product === undefined && solution === undefined) {
        dispatch(setProjects(availableProjects.map((p) => p.name)));
      }
      if (dateFrom !== undefined && dateFrom) {
        const dateString = dateFrom;
        const dateObj = new Date(dateString);
        const timestamp = dateObj.getTime();
        dispatch(setFromDate(timestamp));
      }
      if (dateTo !== undefined && dateTo) {
        const dateString = dateTo;
        const dateObj = new Date(dateString);
        const timestamp = dateObj.getTime();
        dispatch(setToDate(timestamp));
      }

      dispatch(setWorkItemTypeBug(true));
      dispatch(setWorkItemTypeUserStory(true));
    }
  }, [availableProjects.length]);

  React.useEffect(() => {
    dispatch(fetchProjects());
  }, []);

  React.useEffect(() => {
    dispatch(applyFilter());
  }, [filter.projects]);
  return (
    <Grow in={error !== ''}>
      <Alert
        variant="outlined"
        severity="error"
        style={{ position: 'fixed', bottom: 70, right: 10 }}
        action={(
          <IconButton onClick={() => setError('')}>
            <Close />
          </IconButton>
        )}
      >
        <AlertTitle>Invalid URL Parameters</AlertTitle>
        {error}
      </Alert>
    </Grow>
  );
}

export default ParameterHandler;
