import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

// eslint-disable-next-line import/prefer-default-export
export const useStyles = makeStyles((theme: Theme) => createStyles({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  selectLabel: {
    marginTop: 14,
  },
  displayedRows: {
    marginTop: 14,
  },
  iconContainer: {
    display: 'flex',
  },
  exportIcon: {
    marginLeft: 'auto',
    order: 2,
  },
  right: {
    marginLeft: 'auto',
    display: 'flex',
  },
  noResultsCell: {
    textAlign: 'center',
  },
  noResultsIcon: {
    height: 165,
    width: 165,
    color: 'lightgray',
  },
  noResultsText: {
    color: 'lightgray',
  },
  chip: {
    marginTop: 8,
    marginRight: 5,
    collapsedSize: 0,
  },
  viewText: {
    textDecorationLine: 'underline',
    fontStyle: 'italic',
  },
  adminView: {
    paddingTop: 4,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  filterButton: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  noLinkStyle: {
    color: 'black',
    '&:hover': {
      color: 'black',
      textDecoration: 'none',
    },
  },
  mobileProjectButton: {
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  collapse: {
    aline: 'left',
  },
  tableContent: {
    height: 'calc(100vh - 300px)',
  },
}));
