import * as React from 'react';
import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Skeleton,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  fetchSolutions,
  selectAvailableSolutions,
  selectFilter,
  setProjectsFromSolution,
  setSolutions,
  Solution,
  toggleSelectedSolution,
} from './filterSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectStatus } from '../system/systemSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    // @ts-ignore
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflowY: 'scroll',
    maxHeight: 300,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  subHeader: {
    textAlign: 'left',
    paddingLeft: 4,
  },
  listItem: {
    paddingLeft: 0,
  },
  listItemAll: {
    paddingLeft: 0,
    borderBottom: '1px solid lightgray',
    display: 'flex',
  },
  allText: {
    textAlign: 'center',
    paddingBottom: 7.5,
    paddingTop: 7.5,
    color: 'black',
    paddingLeft: 26,
  },
  checkBox: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

export default function SolutionFilterForm() {
  const filterState = useAppSelector(selectFilter);
  const availableSolutions = useAppSelector(selectAvailableSolutions);
  const status = useAppSelector(selectStatus);
  const classes = useStyles();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(fetchSolutions());
  }, []);

  const onSolutionToggle = React.useCallback(
    (value: Solution) => {
      dispatch(toggleSelectedSolution(value));
      dispatch(setProjectsFromSolution());
    },
    [dispatch],
  );
  const allSelected = React.useMemo(
    () => availableSolutions.every((x) => filterState.solutions.includes(x)),
    [filterState.solutions.length, availableSolutions.length, filterState.projects.length],
  );
  const noneSelected = React.useMemo(
    () => filterState.solutions.length === 0,
    [filterState.solutions.length, filterState.projects.length],
  );

  const onSelectAll = React.useCallback(() => {
    if (allSelected) {
      dispatch(setSolutions([]));
      dispatch(setProjectsFromSolution());
    } else {
      dispatch(setSolutions(availableSolutions));
      dispatch(setProjectsFromSolution());
    }
  }, [dispatch, availableSolutions, allSelected]);

  const isLoadingSolutions = React.useMemo(() => status.solutions === 'busy', [status.solutions]);

  const renderPlaceholder = React.useCallback(
    () => [0, 1, 2, 3, 4, 5].map((value) => {
      const labelId = `checkbox-list-label-${value}`;
      return (
        <ListItem className={classes.listItem} key={value} role={undefined} dense button>
          <ListItemIcon>
            <Checkbox
              className={classes.checkBox}
              edge="start"
              tabIndex={-1}
              disableRipple
              inputProps={{ 'aria-labelledby': labelId }}
              color="primary"
            />
          </ListItemIcon>
          <ListItemText
            id={labelId}
            primary={<Skeleton variant="text" width={120} />}
            disableTypography
          />
        </ListItem>
      );
    }),
    [],
  );

  const renderFilteredListItems = React.useCallback(
    (filteredSolutions: Solution[]) => (
      <li key="sectionSolution" className={classes.listSection}>
        <ul className={classes.ul}>
          {filteredSolutions.map((value) => {
            const labelId = `checkbox-list-label-${value}`;
            const isSelected = filterState.solutions.filter((x) => x.name === value.name).length > 0
              && value.products.every((y) => filterState.projects.includes(y));
            return (
              <ListItem
                className={classes.listItem}
                key={value.name}
                role={undefined}
                dense
                button
                onClick={() => onSolutionToggle(value)}
              >
                <ListItemIcon>
                  <Checkbox
                    className={classes.checkBox}
                    edge="start"
                    checked={isSelected}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                    color="primary"
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={value.name} />
              </ListItem>
            );
          })}
        </ul>
      </li>
    ),
    [filterState.solutions, onSolutionToggle, classes.listItem, classes.checkBox],
  );
  return (
    <div>
      <List className={classes.root} subheader={<li />} dense>
        <ListSubheader key="all" className={classes.listItemAll}>
          <Checkbox
            className={classes.checkBox}
            edge="start"
            tabIndex={-1}
            disableRipple
            inputProps={{ 'aria-labelledby': 'all' }}
            color="primary"
            checked={allSelected}
            onClick={() => onSelectAll()}
            indeterminate={!allSelected && !noneSelected}
          />
          <Typography id="all" variant="body2" className={classes.allText}>
            All
          </Typography>
        </ListSubheader>
        {availableSolutions.length > 0 && !isLoadingSolutions
          ? renderFilteredListItems(availableSolutions.slice().sort())
          : null}
        {isLoadingSolutions && renderPlaceholder()}
      </List>
    </div>
  );
}
