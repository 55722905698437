import { Button, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import Layout from '../util/Layout';
import ApplicationBar from './ApplicationBar';

const useStyles = makeStyles(() => createStyles({
  paper: {
    textAlign: 'center',
    paddingTop: 200,
    paddingBottom: 200,
  },
  text: {
    paddingBottom: 20,
  },
}));

export default function NotFound() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <ApplicationBar>
      <Layout>
        <div className={classes.paper}>
          <Typography className={classes.text} component="h1" variant="h3">
            Page Not Found
          </Typography>
          <Button
            onClick={() => history.push('/')}
            variant="contained"
            color="primary"
            disableElevation
          >
            Home
          </Button>
        </div>
      </Layout>
    </ApplicationBar>
  );
}
