import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { IconButton, Popover, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Menu, Clear } from '@mui/icons-material';
import { useAppDispatch, useAppSelector, useDeepEffect } from '../../hooks';
import {
  selectFilter,
  selectProjectsIsSelected,
  validate,
} from '../../../features/filter/filterSlice';
import CustomFooter from '../footer';
import HeaderLogo from '../HeaderLogo';
import SearchForm from '../SearchForm';

const drawerWidth = 350;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundImage: 'url("backdrop.png")',
    backgroundSize: 'cover',
  },
  headerPosition: {
    display: 'flex',
    alignItems: 'center',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    position: 'absolute',
  },
  content: {
    marginTop: theme.spacing(4),
    minHeight: 'calc(100vh - 96px)',
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  noBorder: {
    '& td': {
      borderBottom: 'none',
      borderTop: 'none',
    },
  },
  buttonDiv: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    width: '100%',
  },
  filterRoot: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  formRoot: {
    paddingTop: 10,
    paddingBottom: 10,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100vw',
  },
  list: {
    width: 210,
    paddingBottom: 5,
  },
  showLabelList: {
    transform: 'translateY(7px)',
    paddingBottom: 5,
    paddingTop: 5,
  },
  placeHolder: {
    width: '100%',
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  dateFilter: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 20,
    width: 225,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  filterIcon: {
    marginLeft: 15,
    marginRight: 30,
    color: 'white',
  },
  applyButton: {
    margin: 10,
    marginBottom: 3,
  },
  helpLink: {
    width: 35,
    paddingLeft: '12px',
  },
  helpIcon: {
    width: 'inherit',
    height: 'inherit',
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent gray background
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 999,
  },
  popup: {
    outline: 'none',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    textAlign: 'center',
  },
  closePopupButton: {
    position: 'absolute',
    top: '5px',
    right: '5px',
  },
}));

interface Props {
  children: any;
}

export default function ApplicationBar(props: Props) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [showFilterPopup, setShowFilterPopup] = React.useState(false);

  const { children } = props;

  const toggleFilterPopup = () => {
    setShowFilterPopup(!showFilterPopup);
  };

  const hideFilterPopup = () => {
    setShowFilterPopup(false);
  };

  const filter = useAppSelector(selectFilter);

  const projectsIsSelected = useAppSelector(selectProjectsIsSelected);

  useDeepEffect(() => {
    dispatch(validate());
  }, [filter, projectsIsSelected]);

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <HeaderLogo link="/" className={classes.headerPosition} />
          <div className={classes.placeHolder} />
          <div className={classes.filterRoot}>
            <div>
              <IconButton
                onClick={toggleFilterPopup}
                // aria-describedby={popOverId}
                className={classes.filterIcon}
              >
                <Menu />
              </IconButton>
            </div>
          </div>
          {/* <SearchBar /> */}
          <a
            className={classes.helpLink}
            href="https://support.vertigis.com/hc/sections/8753620022674"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              className={classes.helpIcon}
              alt="help button"
              src={`${process.env.PUBLIC_URL}/helpIcon.svg`}
            />
          </a>
        </Toolbar>
      </AppBar>
      {showFilterPopup && (
        <div className={classes.overlay}>
          <Popover
            open={showFilterPopup}
            onClose={hideFilterPopup}
            disablePortal
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
          >
            <div className={classes.popup}>
              <IconButton onClick={hideFilterPopup} className={classes.closePopupButton}>
                <Clear style={{ fontSize: '35px' }} />
              </IconButton>
              <SearchForm executeOnClose={hideFilterPopup} />
            </div>
          </Popover>
        </div>
      )}

      <main className={classes.content}>{children}</main>
      <CustomFooter />
    </div>
  );
}
