import React from 'react';
import createStyles from '@mui/styles/createStyles';
import { Link } from 'react-router-dom';
import { Typography, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.contrastText,
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.primary.contrastText,
    },
  },
  title: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  image: {
    marginBottom: '4px',
    height: '35px',
  },
}));

interface Props {
  link: string;
  className?: string;
}

// returns the logo of the website.
// in case that the link is non existent there wont be a link element.
function HeaderLogo({ link, className = '' }: Props) {
  const classes = useStyles();
  const BuildNumber = 'v1.2.0';

  if (link) {
    return (
      <Link to={link} className={classes.link}>
        <div className={className}>
          <Typography className={classes.title} variant="h6" noWrap>
            <img className={classes.image} src={`${process.env.PUBLIC_URL}/logo.png`} alt="logo" />
            Product Change Logs
          </Typography>
          <p style={{ fontSize: '14px', margin: 0, marginLeft: 10 }}>{BuildNumber}</p>
        </div>
      </Link>
    );
  }

  return (
    <div className={className}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography className={classes.title} variant="h6" noWrap>
          <img className={classes.image} src={`${process.env.PUBLIC_URL}/logo.png`} alt="logo" />
          Product Change Logs
        </Typography>
        <p style={{ fontSize: '14px', margin: 0, marginLeft: 10 }}>{BuildNumber}</p>
      </div>
    </div>
  );
}

HeaderLogo.defaultProps = {
  className: '',
};

export default HeaderLogo;
