/* eslint-disable react/no-children-prop */
import * as React from 'react';
import DatePicker from '@mui/lab/DatePicker';
import { TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface Props {
  value: Date | null;
  label: string;
  showFormat: boolean;
  // eslint-disable-next-line no-unused-vars
  handleChange: (date: unknown) => void;
  // eslint-disable-next-line react/require-default-props
  inputStyles?: any;
}

const useStyles = makeStyles({
  datePicker: {
    '& .MuiPickersModal-dialog': {
      backgroundColor: 'red !important',
    },
  },
});

export default function DateFilterBase(props: Props) {
  const {
    value, label, handleChange, showFormat, inputStyles,
  } = props;

  const classes = useStyles();
  // checkDateValidation combines the validatioin and the handlechange event together.
  // eslint-disable-next-line no-unused-vars
  const checkDateValidation = (changeDate: (date: unknown) => void, date: Date | null) => {
    changeDate(date);
    if (date !== null) {
      if ((date as any)?.toString() === 'Invalid Date') {
        setError(true);
      } else {
        setError(false);
      }
    }
  };

  const [error, setError] = React.useState(false);

  return (
    <div>
      <DatePicker
        DialogProps={{ className: classes.datePicker }}
        InputAdornmentProps={{
          sx: {
            color: 'white',
          },
        }}
        renderInput={(p) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...p}
            sx={inputStyles}
            helperText={showFormat ? p?.inputProps?.placeholder : null}
            error={error}
          />
        )}
        inputFormat="yyyy/MM/dd"
        mask="____/__/__"
        label={label}
        value={value}
        onChange={(date) => checkDateValidation(handleChange, date)}
      />
    </div>
  );
}
